:root{--fdPopover_Arrow_Offset:0.575rem;} //TODO: remove this, once SAP-THEMING adding this variable back to there library

$shellbar-height: 48px;

@font-face {
    font-family: '72';
    src: url('~@sap-theming/theming-base-content/content/Base/baseLib/baseTheme/fonts/72-Regular-full.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: '72';
    src: url('~@sap-theming/theming-base-content/content/Base/baseLib/baseTheme/fonts/72-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: '72';
    src: url('~@sap-theming/theming-base-content/content/Base/baseLib/baseTheme/fonts/72-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'SAP-icons';
    src: url('~@sap-theming/theming-base-content/content/Base/baseLib/baseTheme/fonts/SAP-icons.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BusinessSuiteInAppSymbols';
    src: url('~@sap-theming/theming-base-content/content/Base/baseLib/baseTheme/fonts/BusinessSuiteInAppSymbols.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SAP-icons-TNT';
    src: url('~@sap-theming/theming-base-content/content/Base/baseLib/baseTheme/fonts/SAP-icons-TNT.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* You can add global styles to this file, and also import other style files */

body {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.fd-shellbar__logo--pex-portal {
    background-image: url("./assets/payroll-express-logo.png");
    height: 74px;
    width: 74px;
    margin-right: 10px;
}

.busy-overlay div {
    background: transparent;
}

.form-set-width-1 {
    max-width: 700px;
    min-width: 240px;
}

.form-set-width-2 {
    max-width: 330px;
    min-width: 240px;
}

.actions {
    margin-top: 2rem;
}

.add-button {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}

fd-input-group[type="search"] [fd-input-group-addon] {
    display: none;
}

.fd-select-button-custom span {
    min-height: calc(var(--fd-forms-height, 36px) - 2px);
}

.fd-form-item {
    margin: 0 0 0.5rem;
}

label.required {
    &::before {
        content: '* ';
        color: #b00;
        font-weight: bold;
    }
}

.use-admin-panel-title {
    font-size: large;
}

fd-select {
    fd-popover {
        width: 100%;
    }
}

use-role-lookup {
    .fdp-table__col--description {
        [fd-table-text] {
            min-width: 5rem !important;
        }
    }
}

.use-lookup-table-container{
    overflow: auto;

    .button-cell{
        min-width: 52px;
        max-width: 52px;
        width: 52px;
        justify-content: center;
    }
}

